import React from 'react';
import { Link } from 'gatsby';

import contacts from '../data/contact';
import pages from '../data/pages';

import HeaderImage from './HeaderImage';

function MenuItem({ page }: { page: { name: string; path?: string } }) {
  return (
    <li className={`${page.name}-link`}>
      <Link activeClassName="active" to={page.path ?? `/${page.name}`}>
        {page.name}
      </Link>
    </li>
  );
}

export default function Header() {
  return (
    <div className="header">
      <div className="title">
        <a href="/">
          <div className="image-wrapper">
            <HeaderImage />
          </div>
        </a>
      </div>
      <ul className="nav menu hidden-480">
        {pages.map((page) => (
          <MenuItem key={`${page.name}-small-display`} page={page} />
        ))}
      </ul>
      <ul className="nav menu visible-480">
        {pages.slice(0, 3).map((page) => (
          <MenuItem key={page.name} page={page} />
        ))}
      </ul>
      <ul className="nav menu visible-480">
        {pages.slice(3, 6).map((page) => (
          <MenuItem key={page.name} page={page} />
        ))}
      </ul>
      <div className="content contact">
        <div className="content-row">
          <div className="container">
            <div className="row">
              <div className="span12 center">
                <div className="contact-container">
                  {contacts.map((contact) => (
                    <a key={contact.name} href={contact.link} target="_new" className="contact-box">
                      {contact.name}
                    </a>
                  ))}
                  <div className="inline-block">
                    or
                    <a className="contact-box email" href="#">
                      moc.liamg <span style={{ display: 'none' }}>do not copy</span>ta zgnow.tsirk
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
