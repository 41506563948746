export default [
  {
    name: 'home',
    path: '/',
  },
  {
    name: 'about',
  },
  {
    name: 'projects',
  },
  {
    name: 'papers',
  },
  {
    name: 'talks',
  },
  {
    name: 'news',
  },
];
