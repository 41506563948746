export default [
  {
    name: 'Gatsby',
    link: 'https://www.gatsbyjs.org',
  },
  {
    name: 'bootstrap',
    link: 'http://getbootstrap.com/',
  },
  {
    name: 'less',
    link: 'http://lesscss.org/',
  },
];
