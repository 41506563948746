import React from 'react';
import type { ReactNode } from 'react';

import libraries from '../data/libraries';

import Header from './Header';

import '../css/main.less';

export type LayoutProps = { children: ReactNode };

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />
      <div className="loading-row">
        <div className="container">
          <div className="row">
            <div className="span4 offset4">
              <div className="padding-lr-20">
                Loading...
                <div className="progress progress-striped active">
                  <div className="bar" style={{ width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content" id="main">
        {children}
      </div>
      <div className="footer">
        <div className="container">
          <div className="row lib-row">
            <div className="span10 offset1">
              Powered by
              {libraries.map((library, i) => (
                <>
                  {i > 0 ? ' / ' : ' '}
                  <a key={library.link} href={library.link} target="_new">
                    {library.name}
                  </a>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="span12 lib-line">&nbsp;</div>
          </div>
        </div>
        <div className="container">
          &copy; 2011-{new Date().getFullYear()} Krist Wongsuphasawat. All rights reserved.
        </div>
      </div>
    </>
  );
}
