export default [
  {
    name: 'Twitter',
    link: 'https://twitter.com/kristw',
    detail: 'I tweet about visualization, technology, design, web, etc.',
  },
  {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/pub/krist-wongsuphasawat/17/b16/279',
    detail:
      'My professional connections. If we do not know each other before, please do not send me an invitation with blank message. At least tell me something about yourself.',
  },
  {
    name: 'github',
    link: 'https://github.com/kristw',
    detail: "I commit some code here, but please don't just my coding skills from this alone.",
  },
  {
    name: 'slideshare',
    link: 'https://www.slideshare.net/kristw/presentations',
    detail: 'I put slides from all my talks here.',
  },
  {
    name: 'blog',
    link: 'https://medium.com/@kristw',
    detail: 'I occasionally write up something to share my knowledge and experience.',
  },
  {
    name: 'flickr',
    link: 'https://www.flickr.com/photos/elixirk/sets/',
    detail: 'My photo gallery',
  },
];
