import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import type { IArtDirectedImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

import { Breakpoints } from './constants';

import '../css/headerImage.css';

export default function HeaderImage() {
  const data: Queries.HeaderComponentQuery = useStaticQuery(graphql`
    query HeaderComponent {
      nameTagLarge: file(absolutePath: { regex: "assets/name.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 456, placeholder: BLURRED, layout: FIXED, quality: 80)
        }
      }
      nameTagMedium: file(absolutePath: { regex: "assets/name.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 410, placeholder: BLURRED, layout: FIXED, quality: 80)
        }
      }
      nameTagSmall: file(absolutePath: { regex: "assets/name.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 320, placeholder: BLURRED, layout: FIXED, quality: 80)
        }
      }
      # nameTagTiny: file(absolutePath: { regex: "assets/name.png/" }) {
      #   childImageSharp {
      #     gatsbyImageData(width: 228, placeholder: BLURRED, layout: FIXED, quality: 80)
      #   }
      # }
    }
  `);

  if (!data) return null;

  const images = [
    {
      media: `(min-width: ${Breakpoints.minRps4Width}px) 456px`,
      image: getImage(data.nameTagLarge as ImageDataLike),
    },
    {
      media: `(max-width: ${Breakpoints.maxRps3Width}px) 320px`,
      image: getImage(data.nameTagSmall as ImageDataLike),
    },
  ] as IArtDirectedImage[];
  const artDirection = withArtDirection(getImage(data.nameTagMedium as ImageDataLike) as IGatsbyImageData, images);

  return (
    <GatsbyImage
      loading="eager"
      objectFit="none"
      alt="logo"
      className="art-directed-header-image"
      image={artDirection}
      sizes={`${images.map((m) => m.media).join(', ')}, 410px`}
    />
  );
}
