export const Breakpoints = {
  /* Portrait phones and down */
  maxRps1Width: 480,
  /* Landscape phone to portrait tablet */
  maxRps2Width: 767,
  /* Landscape tablet and desktop */
  minRps3Width: 768,
  maxRps3Width: 979,
  /* Large desktop */
  minRps4Width: 1200,
} as const;
